import React, { ReactElement } from "react"
import Form, { FormSubmission } from "../components/common/Form"
import Layout from "../components/layout/Layout"
import { sendContact, sendToEmail } from "../core/contact.controller"
import { yellowGradient } from "../css/css"

interface Props {}

function Contact({}: Props): ReactElement {
  const onSubmit = async (values: FormSubmission) => {
    console.log("====================================")
    console.log(values)
    console.log("====================================")
    const formData = new FormData()
    formData.append("form-name", values.formName)
    formData.append("name", values.name)
    formData.append("email", values.email)
    formData.append("inquiring", values.inquiring)
    formData.append("subject", values.subject)
    formData.append("message", values.message)

    try {
      const requests = [sendContact(formData),sendToEmail(values)]
      await Promise.all(requests); // we send both requests in parallel
      alert(
        `Hey ${values.name} your message was sent, we will reach out soon 😊`
      )
    } catch (error) {
      alert(error)
    }
  }
  return (
    <Layout>
      <div
        className="flex-col items-center flex "
        style={{
          backgroundImage: yellowGradient,
        }}
      >
        <h1 className="font-bold text-4xl mt-32  text-center">Contact us.</h1>
        <div className="w-11/12 md:w-3/5   p-8 my-4  rounded-md shadow-sm bg-yellow-50">
          <p className="mb-8 text-center md:text-left">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ut
            laudantium pariatur sed officia veniam possimus nam, fuga aliquam
            maiores consectetur vero? Doloribus possimus minus molestiae itaque
            laborum ducimus placeat officiis.
          </p>
          <Form formName="contact-us" onSubmit={onSubmit} netlify={true}></Form>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
