import { ChatIcon } from '@heroicons/react/solid'
import React, { ReactElement, useState } from 'react'
import Button from '../Button'
import { InputField, TextArea } from '../Input'
import ListSelect from '../Input/ListSelect'


export interface FormSubmission {
    formName : string,
    name: string,
    email: string,
    inquiring: string,
    subject: string,
    message: string
}
interface FormProps {
    formName: string
    netlify?: boolean,
    onSubmit: (values: FormSubmission) => void
}
const options = [
    { name: 'Career' },
    { name: 'Support' },
    { name: 'Product' },
]

function Form({ formName, netlify, onSubmit }: FormProps): ReactElement {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [selected, setSelected] = useState(options[0])
    const [message, setMessage] = useState("")
    const [errors, setErrors] = useState({
        "name": "",
        "subject": "",
        "message": "",
    })
    const validate = () => {
        const _errors = {
            "name": "",
            "subject": "",
            "message": ""
        }
        let hasError = false;
        if (name.length == 0) {
            hasError = true;
            errors["name"] = "Please provide a name to contact you later"
        }
        if (subject.length == 0) {
            hasError = true;
            errors["subject"] = "Please provide a subject for your need"
        }
        if (message.length == 0) {
            hasError = true;
            errors["message"] = "The message is required"
        }

        setErrors((prev)=>_errors);
        setTimeout(() => {
            console.log(errors);

            setErrors({
                "name": "",
                "message": "",
                "subject": ""
            })
        }, 5000)
        console.log("is it valide " + !hasError);

        return !hasError;
    }
    const _onSubmit = () => {
        const valide = validate()
        if (valide)
            onSubmit({
                formName : formName,
                name,
                email,
                subject,
                message,
                inquiring: selected.name
            })
        else
            alert("Please make sure all fields are filled")
    }
    return (
        <form name={formName} data-netlify={netlify}>
            <input type="hidden" name="form-name" value={formName} />
            <InputField className="mb-8" label="Your Name" name="name" type="text" placeholder="ex: john smith"
                errorMessage={errors.name}
                onChange={(value) => {
                    setName(value)
                }}

            />
            <InputField className="mb-8" label="Your Email" name="email"  type="email" placeholder="your@email.com"
                onChange={(value) => {
                    setEmail(value)
                }}

            />
            <ListSelect
                className="mb-8"
                options={options}
                name="inquiring"
                selected={selected}
                label="I'm inquiring for"
                onChange={(value) => {
                    setSelected(value)
                }}
            />
            <InputField className="mb-8" label="Subject" name="subject"  type="text" placeholder="ex: support"
                errorMessage={errors.subject}
                onChange={(value) => {
                    setSubject(value)
                }}
            />

            <TextArea className="mb-8" label="Message" name="message" placeholder="Your message" maxLines={5}
                errorMessage={errors.message}
                onChange={(value) => {
                    setMessage(value)
                }}
            />
            <Button className=" py-4 px-6 " onClick={_onSubmit} primary submit>
                <span className="inline-block">Send it</span>
                <ChatIcon className="ml-4 h-5 w-5 inline"></ChatIcon></Button>
        </form>
    )
}

export default Form
