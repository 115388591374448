import { FormSubmission } from "../components/common/Form";

export const FORM_API_ENDPOINT = "/"
export const EMAIL_API_ENDPOINT = "/.netlify/functions/sendmail"
export async function sendContact(data: FormData) {

    
    try {
        const res = await fetch(FORM_API_ENDPOINT, {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(data).toString(),
          })
        return res;
    } catch (error) {
        console.log("An error occured details : " +error);
    }
  
}


export async function sendToEmail(data : FormSubmission) {
    try {
        const res = await fetch(EMAIL_API_ENDPOINT, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          })
        if(res.status != 200){
            throw new Error("Request was not accepted")
        }
        return res;
    } catch (error) {
        console.log("An error occured details : " +error);
    }

}
