import React, { ReactElement } from 'react'
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

export interface Option {
    name:string
}
interface ListSelectProps {
    label?: string,
    name: string,
    className?:string,
    selected : Option,
    onChange : (value: any) => void
    options : Option[]
}


export default function ListSelect({label,name,options,className,selected,onChange} : ListSelectProps) {

  return (
    <div className={className}>
      {(label) &&  <label htmlFor={name} className="mb-2">{label}</label>}
      <Listbox refName={name} value={selected} onChange={onChange}>
        <div className="relative mt-1">
          <Listbox.Button className="relative bg-brand-100 w-full py-2 pl-3 pr-10 text-left bg-white rounded-sm shadow-sm cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
            <span className="block truncate">{selected.name}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {/* <InputField type="text" placeholder="search" name="search_opts" initialValue=""/> */}
            <Listbox.Options className="absolute ring-0 bg-brand-200 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60  focus:outline-none sm:text-sm">
              {options.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active,selected }) =>
                    `${active ? 'text-amber-900 cursor-pointer bg-amber-100 bg-brand-400' : 'text-gray-900'}
                         ${selected ? "bg-brand-400" :""}
                          cursor-pointer select-none relative py-2 pl-10 pr-4`
                  }
                  value={person}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`${
                          selected ? 'font-medium' : 'font-normal'
                        } block truncate`}
                      >
                        {person.name}
                      </span>
                      {selected ? (
                        <span
                          className={`${
                            active ? 'text-amber-600' : 'text-amber-600'
                          }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                        >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}
