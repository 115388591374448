import classNames from 'classnames'
import React, { ChangeEventHandler, ReactElement, useState } from 'react'
import TextArea from './TextArea'

type ValueType = string | number | readonly string[]
interface InputProps {
    label?: string
    name: string
    type: string
    className ?: string
    placeholder?:string,
    errorMessage?:string,
    onChange : (value : string) => void
}

export function InputField({ label, name, type,className, placeholder,errorMessage,onChange}: InputProps): ReactElement {
   
    return (
        <div className="flex flex-col">
            {(label) &&  <label htmlFor={name} className="mb-2">{label}</label>}
            <input name={name}  type={type} placeholder={placeholder} className={
                classNames(className,(errorMessage && "border-red"),"rounded-sm p-2 shadow-sm transition-all duration-150 ease-in border-2 border-transparent outline-none bg-brand-100 hover:border-brand-500 focus:border-brand-500")
            }
            onChange={(e) => onChange(e.target.value)}
            />
            {(errorMessage && <span className="text-red">{errorMessage}</span>)}
        </div>
    )
}

export {TextArea}